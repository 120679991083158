import { ref } from 'vue'

function createDebounce() {
  let timeout: any = null
  return function (fnc: () => void) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fnc()
    }, 1000)
  }
}

function viewItem(route: string, row: any) {
  navigateTo(`${route}/${row.uuid}`)
}

function newWindowItem(route: string, row: any) {
  if (row?.uuid) {
    window.open(`${route}/${row.uuid}`)
  } else {
    window.open(`${route}`)
  }

}

function newEntity(route: string) {
  navigateTo(`${route}/new`)
}

function downloadStringFile(object: any, fileName: string) {
  const url = URL.createObjectURL(
    new Blob(
      // eslint-disable-next-line prefer-regex-literals
      [object.replace(new RegExp(',', 'g'), ';')],
      {
        type: 'application/octet-stream'
      }
    )
  )
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
}

export function getTableInitialVariables(sortBy: string, sortDesc: boolean, queryName: string) {
  const containerWidth = ref(document.body.clientWidth)
  const config = useRuntimeConfig()

  function showPreview(entity: string, uuid: string) {
    window.open(`${config.public.webUrl}/${entity}/${uuid}?type=preview`,
      '_blank')
  }

  window.addEventListener('resize', () => { containerWidth.value = document.body.clientWidth })

  return {
    loading: ref(false),
    first: ref(10),
    sortBy: ref(sortBy),
    sortDesc: ref(sortDesc),
    defaultSort: ref({ prop: sortBy, order: sortDesc ? 'descending' : 'ascending' }),
    search: ref(''),
    showChild: ref(false),
    numberOfFiltersActive: ref(0),
    debounce: createDebounce(),
    viewItem,
    newWindowItem,
    newEntity,
    downloadStringFile,
    showPreview,
    page: ref(1),
    filterForm: ref(false),
    filterExists: ref(false),
    itemsPerPageArray: [10, 25, 50, 200],
    containerWidth,
    defaultResponse: {
      [queryName]: {
        data: [],
        paginatorInfo: {
          total: 0,
          currentPage: 1,
          lastPage: 1
        }
      }
    }
  }
}
